import { createAsyncThunk } from "@reduxjs/toolkit";
import { CHAT_API } from "../../../config";
import { get, post, put } from "../../../library/Requests/helpers";

export const getUserChatId = createAsyncThunk(
  "chat/getUserChatId",
  async ({ rcId, userId }: { rcId: any; userId?: any }) =>
    await get(
      `${CHAT_API}/api/auth/getChatId?id=RC_${rcId}${
        userId ? `&userId=${userId}` : ""
      }`
    )
);

export const addMessage = createAsyncThunk(
  "chat/addMessage",
  async (body: any) => await post(`${CHAT_API}/api/messages/addmsg`, body)
);

export const getMessages = createAsyncThunk(
  "chat/getMessages",
  async (body: any) => await post(`${CHAT_API}/api/messages/getmsg`, body)
);

export const getGroupList = createAsyncThunk(
  "chat/getGroupList",
  async (body: any) =>
    await get(`${CHAT_API}/api/group/get-all-groups?user_id=${body?.user_id}`)
);

export const updateReadMessagStatus = createAsyncThunk(
  "chat/updateReadMessageStatus",
  async (body: any) =>
    await put(`${CHAT_API}/api/messages/update-read-message-status`, body)
);
