import React, { useCallback } from "react";
import { IoMdShare } from "react-icons/io";
import { LuForward } from "react-icons/lu";
import { useHistory } from "react-router-dom";

type prop = {
  data: any;
  replacePath: boolean;
  iconType?: "SHARE" | "FORWARD";
};
const ShareMessages = ({ data, replacePath, iconType = "SHARE" }: prop) => {
  const history = useHistory();

  const handleShare = useCallback(() => {
    console.log(data, "123");

    if (replacePath) {
      history.replace("/chatroom#share", { data });
    } else {
      history.push("/chatroom#share", { data });
    }
  }, []);

  if (iconType == "FORWARD") {
    return (
      <>
        <LuForward onClick={handleShare} size={18} />
      </>
    );
  }
  return (
    <>
      <IoMdShare onClick={handleShare} size={18} />
    </>
  );
};

export default ShareMessages;
