import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getGroupList } from "./chatService";

export interface ChatState {
  loading: boolean;
  error: any;
  chats: {};
  groups: [];
}

const initialState: ChatState = {
  loading: false,
  error: null,
  chats: [],
  groups: [],
};

export const bloodBankSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setLoading: (state: ChatState, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    updateErrorMessage: (state: ChatState, action: PayloadAction<boolean>) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder: any) => {
    // getGroupList
    builder.addCase(getGroupList.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getGroupList.fulfilled,
      (state: any, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.groups = action.payload?.data;
      }
    );
    builder.addCase(getGroupList.rejected, (state: any, action: any) => {
      console.log({ action });
      state.loading = false;
      state.error = action.error?.message ?? "Something went wrong";
    });
  },
});

export const { setLoading, updateErrorMessage } = bloodBankSlice.actions;

export default bloodBankSlice.reducer;
