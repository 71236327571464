import styled from "styled-components";

export const BookingStyled = styled.div`
  width: 100%;

  .appoint-order {
    padding: 0 20px;
  }
  .ordertable {
    margin-top: 12px;
  }

  .ordertable Table {
    --bs-table-bg: transparent !important;
    border-radius: 5px;
    border: 1px solid #d6cece;
  }
  .ordertable thead {
    border-radius: 5px 5px 0px 0px;
    background: #d3edfc;
  }
  .ordertable th {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .ordertable td {
    color: #808080;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
  }
  .ordertable tr {
    border: 1px solid #d6cece;
  }
  .ordertable .action-report {
    text-align: center;
  }
  .ordertable .action-report svg {
    cursor: pointer !important;
  }
  .orderTable .actions {
    display: flex;
    align-items: center;
  }
  .ordertable .actions {
    text-align: center;
  }
  .ordertable .actions svg {
    cursor: pointer;
  }
  .ordertable .icon-space {
    margin: 0 6px;
  }
  @media (max-width: 768px) {
    .ordertable .icon-space {
      margin: 0 0;
    }
    .appoint-order {
      padding: 0px 0px;
    }

    .ordertable h5 {
      font-size: 15px !important;
    }
    .ordertable-search-div {
      display: flex;
      flex-direction: column-reverse;
    }
  }
  .ordertable h5 {
    color: #545353;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
    display: flex;
    align-items: center;
  }
  .Details-Clint-Wallet-select-div {
    display: flex;
    align-items: center;
  }
  .Details-Clint-Wallet-select-div p {
    color: #808080;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
    display: flex;
    align-items: center;
  }

  .Details-Clint-Wallet-select-div p.active {
    border-bottom: 2px solid #7c4dff;
    padding-bottom: 4px;
  }
  .custom-border-radius {
    border-radius: 2rem;
  }

  .create-order-btn {
    border: 1px solid #7c4dff;
    color: #7c4dff;
  }
  .pagination-div {
    display: flex;
    justify-content: center;
  }
  .ordertable td {
    img {
      height: 3rem;
      width: 3rem;
    }
  }
`;
