import React from "react";
import { useHistory } from "react-router";
import { SideMenuStyled } from "./SideMenu.styled";
import { ReactComponent as Dash } from "../../assets/images/Navbaricons/dashboard.svg";
import { ReactComponent as Book } from "../../assets/images/Navbaricons/book.svg";
import { ReactComponent as Cata } from "../../assets/images/Navbaricons/cata.svg";
import { ReactComponent as Mark } from "../../assets/images/Navbaricons/mark.svg";
import { ReactComponent as Rem } from "../../assets/images/Navbaricons/rem.svg";
import { ReactComponent as Task } from "../../assets/images/Navbaricons/task.svg";
import { ReactComponent as Reporticon } from "../../assets/images/Navbaricons/report.svg";
import { ReactComponent as Ordersicon } from "../../assets/images/Navbaricons/order.svg";
import { ReactComponent as Proficon } from "../../assets/images/Navbaricons/prof.svg";
import { ReactComponent as Clinicicon } from "../../assets/images/Navbaricons/clinic.svg";
import { ReactComponent as Blogicon } from "../../assets/images/Navbaricons/blog.svg";
import { ReactComponent as Revicon } from "../../assets/images/Navbaricons/revenue.svg";
import { ReactComponent as RightArrow } from "../../assets/icons/rightarrow.svg";
import { MdOutlineLogout } from "react-icons/md";
import { IoIosMan } from "react-icons/io";
import { CiChat1 } from "react-icons/ci";

const Sidebar = () => {
  const history = useHistory();
  const selectedUrl = window.location.pathname;

  const condition = true;
  const menuLinks = [
    { title: "Dashboard", id: "Dashboard", icon: <Dash />, link: "/" },
    {
      title: "Orders",
      id: "Orders",
      icon: <Ordersicon />,
      link: condition ? "/Orders" : "/orders/create-order",
    },

    { title: "Bookings", id: "Bookings", icon: <Book />, link: "/Bookings" },
    {
      title: "Prescription",
      id: "Prescription",
      icon: <Reporticon />,
      link: "/hrprescription",
    },
    {
      title: "RFQ",
      id: "RFQ",
      icon: <Proficon />,
      link: "/rfq",
    },
    {
      title: "Clients",
      id: "Clients",
      icon: <Dash />,
      link: condition ? "/clients" : "/clients/detailsclintwallet",
    },
    // {
    //   title: "Employee",
    //   id: "Employee",
    //   icon: <IoIosMan />,
    //   link: "/employee",
    // },

    {
      title: "Chatroom",
      id: "Employee",
      icon: <CiChat1 />,
      link: "/chatroom",
    },
  ];
  const gotoNewPage = (url: string) => {
    if (url) {
      history.push(url);
    }
  };
  const gotoLogoutPage = () => {
    localStorage.clear();
    window.location.href = "/signin";
  };

  return (
    <SideMenuStyled>
      <div className="sidebar-menu">
        {menuLinks.map((item) => (
          <div
            key={item.id}
            className={selectedUrl.startsWith(item.link) ? "active" : ""}
            onClick={() => gotoNewPage(item.link)}
          >
            <h4
              style={{
                color:
                  (item.title === "Orders" &&
                    selectedUrl === "/orders/create-order") ||
                  (item.title === "clients" &&
                    selectedUrl === "/clients/detailsclintwallet")
                    ? "purple"
                    : "",
              }}
            >
              {/* <span className="sidebar-icon">
                {selectedUrl === item.link ? (
                  <RightArrow style={{ width: "20px" }} />}
                ) : (
                  item.icon
                )}
              </span> */}
              {item.title}
            </h4>
          </div>
        ))}
        <div onClick={gotoLogoutPage}>
          <h4>
            {/* <span className="sidebar-icon"></span> */}
            <MdOutlineLogout className="logoutIcon" />
            Logout
          </h4>
        </div>
      </div>
    </SideMenuStyled>
  );
};

export default Sidebar;
