import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Dropdown from "react-bootstrap/Dropdown";
import { ReactComponent as Report } from "../../../../assets/icons/report.svg";
import { ReactComponent as Action } from "../../../../assets/icons/action1.svg";
import { ReactComponent as Share } from "../../../../assets/icons/share.svg";
import { ReactComponent as Download } from "../../../../assets/icons/download.svg";
import { ReactComponent as Call } from "../../../../assets/icons/call.svg";
import { ReactComponent as Searchbar } from "../../../../assets/icons/searchbar.svg";
import { ReactComponent as Whatsapp } from "../../../../assets/icons/whats-app.svg";
import { ReactComponent as Gmail } from "../../../../assets/icons/gmail.svg";
import {
  getAllBookingListAPI,
  // getMyBookingsAPI,
} from "../../../../redux/slices/dashboard/dashboardService";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import PatientDetailModal from "../../../../components/PatientDetailModal/Patientmodal";
import { getPrescriptionsAPI } from "../../../../redux/slices/orders/orderService";
import PrescriptionModal from "./PrescriptionModal"; // Import the modal here
import { useHistory } from "react-router-dom";

const Appointorder = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { bookingsList } = useSelector(
    (ReduxState: any) => ReduxState.dashboard
  );
  const [lgShow, setLgShow] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [prescriptionModalShow, setPrescriptionModalShow] = useState(false); // Prescription modal state
  const [selectedPrescription, setSelectedPrescription] = useState<any>(null); // For storing matched prescription
  const [searchQuery, setSearchQuery] = useState("");
  const [allPrescriptions, setAllPrescriptions] = useState([]);
  const [allReports, setAllReports] = useState([]);

  const getLatestData = () => {
    dispatch(getAllBookingListAPI({ searchText: "", count: 100 }));
    // dispatch(getMyBookingsAPI(3));
  };

  useEffect(() => {
    getLatestData();
  }, []);

  const handleModalOpen = (patient: any) => {
    setSelectedPatient({
      ...patient,
      ...patient?.user,
    });
    setLgShow(true);
  };

  const handleModalClose = () => {
    setLgShow(false);
    setSelectedPatient(null);
  };

  const handleShare = (platform: string, patient: any) => {
    const url = window.location.href; // Current URL
    const message = `Check out this appointment details for ${patient?.first_name}:\n${url}`;

    switch (platform) {
      case "whatsapp":
        window.open(
          `https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`,
          "_blank"
        );
        break;
      case "email":
        window.open(
          `mailto:?subject=Appointment Details&body=${encodeURIComponent(
            message
          )}`,
          "_self"
        );
        break;
      default:
        break;
    }
  };

  const onButtonClick = () => {
    fetch("SamplePDF.pdf").then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "SamplePDF.pdf";
        alink.click();
      });
    });
  };

  const filteredBookings = bookingsList?.bookings?.filter(
    (item: any) =>
      item?.user?.first_name
        ?.toLowerCase()
        ?.includes(searchQuery.toLowerCase()) ||
      item?.id?.toString().includes(searchQuery)
  );

  const getPrescriptions = async () => {
    const res1 = await dispatch(getPrescriptionsAPI("prescriptions"));
    const resJson1 = JSON.parse(JSON.stringify(res1));
    setAllPrescriptions(resJson1?.payload?.data?.bookingAttachments);

    const res2 = await dispatch(getPrescriptionsAPI("reports"));
    const resJson2 = JSON.parse(JSON.stringify(res2));

    setAllReports(resJson2?.payload?.data?.bookingAttachments);
  };

  useEffect(() => {
    getPrescriptions();
  }, []);

  // Function to handle prescription click
  const handlePrescriptionClick = (bookingId: any) => {
    const matchedPrescription = allPrescriptions.find(
      (prescription: any) => prescription.booking_id === bookingId
    );
    console.log("matchedPrescription : ", matchedPrescription);

    setSelectedPrescription(matchedPrescription);
    setPrescriptionModalShow(true); // Open the prescription modal
  };

  const handleReportClick = (bookingId: any) => {
    const matchedReport = allReports.find(
      (report: any) => report.booking_id === bookingId
    );
    console.log("matchedReport : ", matchedReport);

    setSelectedPrescription(matchedReport);
    setPrescriptionModalShow(true); // Open the prescription modal
  };

  const handleToAllBookingList = () => {
    history.push("/Bookings");
  };

  return (
    <div className="appoint-order">
      <div className="search-bar">
        <span className="search-icon">
          <Searchbar />
        </span>
        <input
          type="text"
          placeholder="For Patient or Serial Number"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>

      <div className="ordertable">
        <Table responsive="sm">
          <thead>
            <tr>
              <th>Sl No</th>
              <th>Employee ID</th>
              <th>Sex</th>
              <th>Name</th>
              <th>Age</th>
              <th>Number</th>
              <th>Email Id</th>
              <th>Schedule Date</th>
              <th>Status</th>
              <th>Report</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredBookings?.slice(0, 20)?.map((item: any, index: any) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{item?.id}</td>
                <td>N/A</td>
                <td
                  onClick={() => handleModalOpen(item)}
                  style={{ color: "purple", cursor: "pointer" }}
                >
                  {`${item?.user?.first_name} ${item?.user?.last_name}`}
                </td>
                <td>{item?.user?.age}</td>
                <td>{item?.user?.phone}</td>
                <td>{item?.user?.email || "N/A"}</td>
                <td>
                  {item?.collection_1_date
                    ? moment(parseInt(item?.collection_1_date)).format(
                        "DD-MM-YYYY"
                      )
                    : "N/A"}
                </td>
                <td>{item?.status?.replace("_", " ")?.toUpperCase()}</td>
                <td className="action-report">
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => handleReportClick(item.id)}
                  >
                    <Report title="Report" />
                  </span>
                </td>
                <td className="actions">
                  <Dropdown>
                    <Dropdown.Toggle
                      as={Share}
                      id="dropdown-custom-components"
                    />

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => handleShare("whatsapp", item?.user)}
                      >
                        <Whatsapp /> WhatsApp
                      </Dropdown.Item>

                      <Dropdown.Item
                        onClick={() => handleShare("email", item?.user)}
                      >
                        <Gmail /> Email
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <span className="icon-space"></span>
                  <a href={`tel:${item?.user?.phone}`}>
                    <Call />
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        <PatientDetailModal
          show={lgShow}
          onHide={handleModalClose}
          patient={selectedPatient}
        />

        <PrescriptionModal
          show={prescriptionModalShow}
          onHide={() => setPrescriptionModalShow(false)}
          prescriptionData={selectedPrescription}
        />

        <div className="viewbtn">
          <button onClick={handleToAllBookingList}>View All</button>
        </div>
      </div>
    </div>
  );
};

export default Appointorder;
