import React, { useEffect, useState } from "react";
import { BsEmojiSmileFill } from "react-icons/bs";
import { IoIosAttach, IoMdSend } from "react-icons/io";
import styled from "styled-components";
import FileInput from "./FileInput";
import { messageTypeDto } from "./SharedMessageCard";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export default function ChatInput({
  handleSendMsg,
  chatId,
  typingStatusChangeHandler,
}: any) {
  const [msg, setMsg] = useState("");
  const [isShiftKeyPressing, setIsShiftKeyPressing] = useState(false);

  useEffect(() => {
    setMsg("");
    typingStatusChangeHandler(false);
  }, [chatId, typingStatusChangeHandler]);

  const clearState = () => {
    setMsg("");
  };

  const sendChat = () => {
    const isEmpty = msg?.toString().replace(/<\/?[^>]+(>|$)/g, "")
      ? false
      : true;

    if (!isEmpty && msg && msg?.trim()?.length > 0) {
      handleSendMsg(msg, "TEXT");
      clearState();
    }
  };

  const handleChatFileSend = (url: string, fileType: messageTypeDto) => {
    handleSendMsg(url, fileType);
    clearState();
  };

  return (
    <Container>
      <div className="chatInputContainer">
        <FileInput
          handleFileSend={handleChatFileSend}
          handleClose={clearState}
        />
        <ReactQuill
          theme="snow"
          className="w-100"
          value={msg}
          placeholder="Message here..."
          onKeyDown={(e) => {
            if (!isShiftKeyPressing && e?.key === "Enter") {
              sendChat();
            }
            if (e?.key == "Shift") {
              setIsShiftKeyPressing(true);
            }
          }}
          onKeyUp={(e) => {
            if (e?.key == "Shift") {
              setIsShiftKeyPressing(false);
            }
          }}
          onChange={(value: any) => {
            const isEmpty = value?.toString().replace(/<\/?[^>]+(>|$)/g, "")
              ? false
              : true;

            if (!isEmpty) {
              typingStatusChangeHandler(true);
            } else {
              typingStatusChangeHandler(false);
            }
            setMsg(value);
          }}
        />
      </div>
      {/* <button type="submit">
        <IoMdSend />
      </button> */}
      {/* <div className="input-container" onSubmit={(event) => sendChat(event)}>
        {isFileSelect ? (
          <div className="flex-full"></div>
        ) : (
          <>
            <input
              type="text"
              placeholder="type your message here"
              onChange={(e) => {
                const value = e?.target?.value;
                if (value?.trim()) {
                  typingStatusChangeHandler(true);
                } else {
                  typingStatusChangeHandler(false);
                }
                setMsg(value);
              }}
              value={msg}
            />
          </>
        )}
      </div> */}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  .chatInputContainer {
    position: relative;
    width: 100%;
  }

  .quill {
    flex: 1;

    .ql-toolbar {
      background: #f0f0f0;
      // border-radius: 10px 10px 0 0;
      border-left: 0px !important;
      border-right: 0px !important;
    }

    .ql-container {
      // border-radius: 0px 0px 10px 10px;
      max-height: 150px;
      min-height: 80px;
      overflow-y: auto;

      border-left: 0px !important;
      border-right: 0px !important;
    }

    .ql-active {
      background: #0078ff;
      color: white !important;
      stroke: white !important;
      border-radius: 5px;
      margin: 0px 5px;

      svg > * {
        stroke: white !important;
      }
    }
  }
`;
