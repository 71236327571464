import React from "react";
import { TimeSlotsComponentStyled } from "./TimeSlotsComponent.styled";

// Utility function to convert 12-hour time to a 24-hour format for easier comparisons
const convertTo24Hour = (time12h: string) => {
  const [firstPart] = time12h.split("to");
  const trimmedTime = firstPart.trim(); // Trim any extra spaces

  // Split the time into components (time and AM/PM)
  const [time, modifier] = trimmedTime.split(" ");
  let [hours, minutes] = time.split(":");

  // Convert hours to 24-hour format
  if (modifier.toUpperCase() === "PM" && hours !== "12") {
    hours = (parseInt(hours, 10) + 12).toString();
  }
  if (modifier.toUpperCase() === "AM" && hours === "12") {
    hours = "00";
  }
  return `${hours}:${minutes}`;
};

// Function to categorize time slots based on the current time
const categorizeTimeSlots = (timeSlots: any) => {
  const morning = [] as any;
  const afternoon = [] as any;
  const evening = [] as any;

  timeSlots.forEach((time: any) => {
    const hour24 = convertTo24Hour(time).split(":")[0];
    const hourInt = parseInt(hour24, 10);
    if (hourInt < 12) {
      morning.push(time);
    } else if (hourInt < 16) {
      afternoon.push(time);
    } else {
      evening.push(time);
    }
  });

  return { morning, afternoon, evening };
};

const TimeSlot = ({ time, isPast, handleClick, selectedSlot }: any) => {
  return (
    <div
      className={`time-slot slotList ${isPast ? "past" : ""} ${
        selectedSlot === time ? "Selected-time-slot" : ""
      } `}
      onClick={() => handleClick(time)}
      aria-disabled={isPast}
    >
      {time}
    </div>
  );
};

const TimeSlotsComponent = ({ timeSlots, handleClick, selectedSlot }: any) => {
  const currentHour = new Date().getHours();
  const { morning, afternoon, evening } = categorizeTimeSlots(timeSlots);
  // Function to check if a time is past based on the current time
  const isPastTime = (time: any) => {
    const slotHour = parseInt(convertTo24Hour(time).split(":")[0], 10);
    return slotHour < currentHour;
  };

  return (
    <TimeSlotsComponentStyled>
      <div className="time-slots">
        {/* Morning Slots */}
        {morning.length > 0 && (
          <div className="time-slot-section">
            <h3>Morning</h3>
            <div className="time-slot-container">
              {morning.map((time: any, index: any) => (
                <TimeSlot
                  key={index}
                  time={time}
                  isPast={isPastTime(time)}
                  handleClick={handleClick}
                  selectedSlot={selectedSlot}
                />
              ))}
            </div>
          </div>
        )}

        {/* Afternoon Slots */}
        {afternoon.length > 0 && (
          <div className="time-slot-section">
            <h3>Afternoon</h3>
            <div className="time-slot-container">
              {afternoon.map((time: any, index: any) => (
                <TimeSlot
                  key={index}
                  time={time}
                  isPast={isPastTime(time)}
                  handleClick={handleClick}
                  selectedSlot={selectedSlot}
                />
              ))}
            </div>
          </div>
        )}

        {/* Evening Slots */}
        {evening.length > 0 && (
          <div className="time-slot-section">
            <h3>Evening</h3>
            <div className="time-slot-container">
              {evening.map((time: any, index: any) => (
                <TimeSlot
                  key={index}
                  time={time}
                  isPast={isPastTime(time)}
                  handleClick={handleClick}
                  selectedSlot={selectedSlot}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </TimeSlotsComponentStyled>
  );
};

export default TimeSlotsComponent;
