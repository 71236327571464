import { createAsyncThunk } from "@reduxjs/toolkit";
import { SERVER_IP } from "../../../config";
import { post } from "../../../library/Requests/helpers";
import {
  fetchPatients,
  getClients,
  getPatientDetails,
} from "../../../Scenes/graphqlConfig";

export const fetchPatientsAPI = createAsyncThunk(
  "auth/fetchPatientsAPI",
  async () => await post(`${SERVER_IP}/graphql`, JSON.stringify(fetchPatients))
);

export const fetchClientsAPI = createAsyncThunk(
  "auth/fetchClientsAPI",
  async (body: any) =>
    await post(
      `${SERVER_IP}/graphql`,
      JSON.stringify(
        getClients(body?.searchText, body?.count, body?.page, body?.type)
      )
    )
);

export const patientDetailAPI = createAsyncThunk(
  "auth/patientDetailAPI",
  async (patientId: any) =>
    await post(
      `${SERVER_IP}/graphql`,
      JSON.stringify(getPatientDetails(patientId))
    )
);

export const addPatientAPI = createAsyncThunk(
  "auth/addPatientAPI",
  async (body: any) => await post(`${SERVER_IP}/api/v1/doctor/patient`, body)
);
