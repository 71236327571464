import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FaUser } from "react-icons/fa";
import { ChatComponentStyled } from "./ChatComponent.styled";
import { useDispatch, useSelector } from "react-redux";
import {
  getGroupList,
  getUserChatId,
  updateReadMessagStatus,
} from "../../redux/slices/chat/chatService";
import { toast } from "react-toastify";
import { CHAT_API } from "../../config";
import { io } from "socket.io-client"; // Import socket.io-client
import useSendBrowserNotification from "./useSendBrowserNotification";
import ChatContainer from "./ChatContainer";
import { useLocation, useHistory } from "react-router-dom";
import ShareForm from "./ShareForm";
import { IoMdClose, IoMdSearch } from "react-icons/io";

// Updated User type with chatId
type ChatDto = {
  id?: string;
  chatId: string;
  name: string;
  photo: string;
};

export type UserDto = {
  id?: string;
  chatId: string;
  name: string;
  photo: string;
  unReadMessageCount: number;
};

export type groupDto = {
  _id: string;
  name: string;
  description: string;
  logo: string;
  created_by: {
    _id: string;
    username: string;
    avatarImage: string;
  };
  admins: { _id: string; username: string; avatarImage: string }[];
  members: { _id: string; username: string; avatarImage: string }[];
  createdAt: string;
  unReadMessageCount: number;
};

type Message = {
  fromSelf: boolean;
  message: string;
};

const ChatComponent: React.FC = () => {
  const [onlineUsers, setOnlineUsers] = useState<string[]>([]);
  const [users, setUsers] = useState<UserDto[]>([]);
  const [currentUserChatId, setCurrentUserChatId] = useState<string | null>(
    null
  );
  const { user } = useSelector((ReduxState: any) => ReduxState.auth);
  const { groups } = useSelector((ReduxState: any) => ReduxState.chat);
  const [typingStatus, setTypingStatus] = useState<{
    [key: string]: {
      users?: string[];
      typing: boolean;
    };
  }>({});
  const [allUsersLatest, setAllUsersLatest] = useState<UserDto[]>([]);
  const [showGroupForm, setShowGroupForm] = useState(false);
  const [showShareOptions, setShowShareOptions] = useState(false);
  const [search, setSearch] = useState("");
  const [showSearch, setShowSearch] = useState(false);

  const { sendNotification, toggleTitle } = useSendBrowserNotification();

  const dispatch = useDispatch();
  // Parse userId from URL
  // const userIdFromUrl = new URLSearchParams(window.location.search).get(
  //   "userId"
  // );

  const [currentChat, setCurrentChat] = useState<ChatDto | undefined>(
    undefined
  );
  const socket = useRef<any>(null); // Use ref to store socket instance

  const { state, hash } = useLocation();
  const history = useHistory();

  const defaultGroupLogo =
    "https://w7.pngwing.com/pngs/421/822/png-transparent-users-group-encapsulated-postscript-user-profile-group-miscellaneous-black-information-thumbnail.png";

  useEffect(() => {
    console.log(hash);
    if (hash == "#share") {
      setShowShareOptions(true);
    }
  }, [hash]);

  // get all online users status
  const onOnlineUsersChange = useCallback((data: any) => {
    setOnlineUsers(data);
  }, []);

  // get typing status
  const onTypingStatusChange = useCallback((data: any) => {
    console.log(data, "typing");

    setTypingStatus((prev) => {
      const newTypingStatus: any = { ...prev };
      const isStartedTyping = data?.typingStatus === "STARTED_TYPING";
      const from = data?.isGroupChat ? data?.to : data?.from;
      const usersList = newTypingStatus[from];

      if (isStartedTyping) {
        const uniqueUsers = new Set(
          Array.isArray(usersList)
            ? [...usersList, data.fromUser]
            : [data.fromUser]
        );

        newTypingStatus[from] = Array.from(uniqueUsers);
      } else {
        if (Array.isArray(usersList) && usersList.length <= 1) {
          delete newTypingStatus[from];
        } else {
          newTypingStatus[from] = usersList?.filter(
            (user: string) => user !== data?.fromUser
          );
        }
      }
      return newTypingStatus;
    });
  }, []);

  useEffect(() => {
    if (currentUserChatId) {
      socket.current = io(CHAT_API);
      socket.current.emit("add-user", currentUserChatId);

      socket.current.on("update-online-users", onOnlineUsersChange);

      socket.current.on("typing-status", onTypingStatusChange);

      return () => {
        if (socket.current) {
          socket.current.off("update-online-users", onOnlineUsersChange);

          socket.current.off("typing-status", onTypingStatusChange);
        }
      };
    }
  }, [currentUserChatId, onOnlineUsersChange, onTypingStatusChange]);

  const [isMounted, setIsMounted] = useState(true);

  useEffect(() => {
    // Set the isMounted flag to true when the component is mounted
    setIsMounted(true);

    return () => {
      // Set the isMounted flag to false when the component unmounts
      setIsMounted(false);
    };
  }, []);

  const fetchCurrentChatChatId = useCallback(async () => {
    try {
      const res = (await dispatch(getUserChatId({ rcId: user.id }))) as any;
      // Only update state if the component is still mounted
      if (isMounted) {
        setCurrentUserChatId(res.payload?.user?._id);
      }
    } catch (error) {
      console.error("Error fetching current user chat ID:", error);
      toast.error("Failed to fetch current user chat ID");
    }
  }, [dispatch, user.id, currentUserChatId, isMounted]);

  useEffect(() => {
    fetchCurrentChatChatId();
  }, [fetchCurrentChatChatId]);

  // fetching groups
  const getAllGroupDetails = useCallback(async () => {
    return await dispatch(getGroupList({ user_id: currentUserChatId }));
  }, [currentUserChatId, dispatch]);

  useEffect(() => {
    if (currentUserChatId) {
      getAllGroupDetails();
    }
  }, [getAllGroupDetails, currentUserChatId]);

  // useEffect(() => {
  //   // Auto-select user when both users list and currentUserChatId are available
  //   if (users.length > 0 && currentUserChatId && userIdFromUrl) {
  //     const matchedUser = users.find((user) => user.id === userIdFromUrl);
  //     if (matchedUser) {
  //       handleOpenChat(matchedUser);
  //     }
  //   }
  // }, [users, currentUserChatId, userIdFromUrl, chatType]);

  const updateReadMessagStatusApiCall = useCallback(
    async ({ chat, type }: { type: "GROUP-CHAT"; chat: any }) => {
      console.log(chat);

      if (chat?.unReadMessageCount > 0) {
        if (type === "GROUP-CHAT") {
          await dispatch(
            updateReadMessagStatus({
              from: currentUserChatId,
              to: chat?.chatId ? chat?.chatId : chat?.to,
            })
          );
          getAllGroupDetails();
        }
      }
    },
    [currentUserChatId, dispatch, getAllGroupDetails]
  );

  const onGroupNotificationHandler = useCallback(
    async (d: any) => {
      const data = JSON.parse(d);
      console.log(data, "group notification");

      // if incomming meesage is to same group
      if (currentChat?.chatId === data.group_id) {
        // if i am not the sender
        if (data?.from !== currentUserChatId) {
          await updateReadMessagStatusApiCall({
            chat: currentChat,
            type: "GROUP-CHAT",
          });
        }
      } else {
        getAllGroupDetails();
      }
      sendNotification(`${data?.name}`, data?.message);

      // Start toggling the title when a new message is received
      toggleTitle({ title: "New Message Arrived" });

      // // if incomming message is not the group that user opened
      // else {
      // }
    },
    [
      currentUserChatId,
      currentChat,
      getAllGroupDetails,
      updateReadMessagStatusApiCall,
      sendNotification,
      toggleTitle,
    ]
  );

  useEffect(() => {
    if (socket.current) {
      socket.current.on(`group-chat-notification`, onGroupNotificationHandler);
      // socket.current.on(
      //   `msg-notification`,
      //   onIndividualChatNotificationHandler
      // );
    }

    return () => {
      if (socket.current) {
        socket.current?.off(
          `group-chat-notification`,
          onGroupNotificationHandler
        );
        // socket.current.off(
        //   `msg-notification`,
        //   onIndividualChatNotificationHandler
        // );
      }
    };
  }, [socket, onGroupNotificationHandler]);

  useEffect(() => {
    // listining on groupId-notification
    if (Array.isArray(groups) && groups?.length > 0) {
      groups?.forEach((group) => {
        (socket as any)?.current?.emit("join-room", { group_id: group?._id });
      });
    }
  }, [groups, socket]);

  const handleOpenChat = async (chat: any, type: "GROUP-CHAT") => {
    const logo = chat?.logo || defaultGroupLogo;
    setCurrentChat({ ...chat, logo: logo });

    await updateReadMessagStatusApiCall({ chat, type });

    // if (currentUserChatId && chat.chatId) {
    //   getAllMessages(currentUserChatId, chat.chatId);
    // }
  };

  console.log(currentChat, "currentChat");

  // const handleSendMessage = async (e: React.FormEvent) => {
  //   e.preventDefault();
  //   if (newMessage.trim() && selectedChat && currentUserChatId) {
  //     const msgObj = {
  //       from: currentUserChatId,
  //       to: selectedChat.chatId,
  //       message: newMessage.trim(),
  //     };

  //     try {
  //       const res = (await dispatch(addMessage(msgObj))) as any;
  //       if (res?.error) {
  //         toast.error(res.error.message);
  //         return;
  //       }

  //       // Emit message to server
  //       socket.current.emit("send-msg", {
  //         from: currentUserChatId,
  //         to: selectedChat.chatId,
  //         msg: newMessage.trim(),
  //       });

  //       // Add the new message to the local state
  //       setMessages([
  //         ...messages,
  //         { fromSelf: true, message: newMessage.trim() },
  //       ]);
  //       setNewMessage("");
  //     } catch (error) {
  //       console.error("Error sending message:", error);
  //       toast.error("Failed to send message");
  //     }
  //   }
  // };

  const handleSearch = (e: any) => {
    const value = e?.target?.value || "";
    setSearch(value);
  };

  return (
    <ChatComponentStyled>
      {/* show share form */}
      {showShareOptions && (
        <ShareForm
          currentUserChatId={currentUserChatId}
          socket={socket}
          onHide={() => {
            console.log("close form");

            setShowShareOptions(false);
            history.replace("/chatroom");
          }}
          groupsList={groups}
          usersList={users}
        />
      )}
      <div className="chat-container">
        <div className="sidebar">
          {/* <h2>Admins</h2>
          <ul>
            {users.map((user) => (
              <li
                key={user.id}
                onClick={() => handleOpenChat(user, "INDIVIDUAL-CHAT")}
                className={`${selectedChat?.id === user.id ? "selected" : ""} `}
              >
                <div className="user-icon">
                  <FaUser />
                  {onlineUsers?.includes(user?.chatId ?? "") && (
                    <div className="online-user-indicatore"></div>
                  )}
                </div>
                <div className="space-between">
                  <div>
                    {user?.name || ""}{" "}
                    {typingStatus?.[user?.chatId as string] && (
                      <div className="typing">typing...</div>
                    )}
                  </div>
                  {user?.unReadMessageCount > 0 && (
                    <span className="unread_message_circle">
                      {user?.unReadMessageCount}
                    </span>
                  )}
                </div>
              </li>
            ))}
          </ul> */}
          {/* group */}
          <br />
          <div className="d-flex mb-2 p-2 justify-content-between align-items-center">
            {!showSearch && (
              <>
                <h4 className="mb-0">Groups</h4>
                <div>
                  <IoMdSearch
                    size={18}
                    className=""
                    onClick={() => {
                      setShowSearch(true);
                    }}
                  />
                </div>
              </>
            )}
            {showSearch && (
              <>
                <input
                  type="text"
                  className="search"
                  placeholder="Search group..."
                  name=""
                  autoFocus
                  value={search}
                  onChange={handleSearch}
                  id=""
                />
                <IoMdClose
                  size={18}
                  onClick={() => {
                    setShowSearch(false);
                    setSearch("");
                  }}
                />
              </>
            )}
          </div>{" "}
          <ul className="memberListContainer">
            {groups?.map((group: any) => {
              const regex = new RegExp(search, "i");
              if (regex.test(group.name)) {
                return (
                  <li
                    key={group._id}
                    onClick={() => {
                      const data = {
                        ...group,
                        group_id: group?._id,
                        isGroupChat: true,
                        user_id: user?.id,
                        name: group?.name,
                        chatId: group?._id,
                      };
                      handleOpenChat(data, "GROUP-CHAT");
                      // (socket as any)?.current?.emit("join-room", data);
                    }}
                    className={` chat-item ${
                      currentChat?.chatId === group._id ? "selected" : ""
                    }`}
                  >
                    <img
                      className="avatar-sidebar"
                      src={group?.logo || defaultGroupLogo}
                      alt=""
                    />{" "}
                    <div className="space-between flex-grow-1">
                      <div>
                        {group?.name}
                        {typingStatus?.[group?._id] && (
                          <div className="typing">typing...</div>
                        )}
                      </div>
                      {group?.unReadMessageCount > 0 && (
                        <span className="unread_message_circle">
                          {group?.unReadMessageCount}
                        </span>
                      )}
                    </div>
                  </li>
                );
              }
              return <></>;
            })}
          </ul>
        </div>
        <div className="chat-area">
          {currentChat === undefined ? (
            <p className="no-chat">
              Select an Admin or Group to start chatting
            </p>
          ) : (
            <ChatContainer
              currentUserChatId={currentUserChatId}
              currentChat={currentChat}
              socket={socket}
              typingUsers={typingStatus?.[currentChat?.chatId] ?? []}
            />
          )}
        </div>
      </div>
    </ChatComponentStyled>
  );
};

export default ChatComponent;
